<template>
  <div ref="card" class="card" :class="{ 'card--show': show, 'card--matched': isMatched }">
    <div class="card__inner">
      <img class="card__front" :src="frontFilePath" alt="" width="184" height="280">
      <img class="card__back" :src="backFilePath" alt="">
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from 'howler';
import cardTurningStream from '../assets/sound/ES_Multimedia Action 53 - SFX Producer.mp3';

Howler.volume(0.25);

export default {
  name: 'PlayingCard',

  props: {
    show: Boolean,
    frontImage: String,
    backImage: String,
    isMatched: Boolean
  },

  data: () => ({
    cardTurningSound: new Howl({
      src: [cardTurningStream]
    })
  }),

  computed: {
    folderPath () {
      return `${process.env.BASE_URL}img/cards/`;
    },

    frontFilePath () {
      return this.folderPath + this.frontImage;
    },

    backFilePath () {
      return this.folderPath + this.backImage;
    }
  },

  mounted () {
    const randomAngle = Math.random() * 2 - 1;
    this.$refs.card.style.setProperty('--random-angle', `${randomAngle}deg`);
    this.$refs.card.style.setProperty('--random-angle-inverse', `-${randomAngle}deg`);
  },

  watch: {
    show (value) {
      if (value) {
        this.cardTurningSound.play();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  $self: &;
  display: flex;
  justify-content: center;
  perspective: 1000px;

  &,
  &__inner {
    position: relative;
    width: 116px;
    height: 176px;

    @media screen and (min-width: 1250px) {
      width: 118px;
      height: 180px;
    }

    @media screen and (min-width: 1300px) {
      width: 121px;
      height: 184px;
    }

    @media screen and (min-width: 1400px) {
      width: 124px;
      height: 188px;
    }

    @media screen and (min-width: 1500px) {
      width: 126px;
      height: 192px;
    }

    @media screen and (min-width: 1600px) {
      width: 129px;
      height: 196px;

    }

    transition: transform .25s ease-in-out;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
  }

  &--show {
    #{ $self }__inner {
      transform: rotateY(-180deg);
    }
  }

  &--matched {
    opacity: 0.8;
  }

  &__back,
  &__front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  &__front {
    transform: rotateY(-180deg);
  }
}
</style>
