<template>
  <div>
    <span v-text="display" />
  </div>
</template>

<script>
import countdownTimerMixin from '../mixins/countdownTimerMixin';

export default {
  name: 'CountdownTimer',

  mixins: [countdownTimerMixin],

  data: () => ({
    waitExtraSecond: true
  }),

  computed: {
    display () {
      if (this.remainingMilliseconds === 0) {
        return 'Go';
      }

      return this.remainingSeconds;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

div {
  position: relative;
  background: url('../assets/images/hexagon.png');
  background-size: cover;
  height: 10.3rem;
  width: 11.7rem;

  span {
    @include brand-font(normal);

    color: $polished-xl;
    display: block;
    font-size: 2rem;
    font-weight: normal;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    top: 3.9rem;
  }

  @media screen and (min-width: 1024px) {
    height: 15.4rem;
    width: 17.5rem;

    span {
      font-size: 3rem;
      top: 5.8rem;
    }
  }
}
</style>
