<template>
  <div>
    <img src="../assets/images/score-icon.png" alt="Score">
    <span>X{{ score }}</span>
  </div>
</template>

<script>
export default {
  name: 'ScoreCounter',

  props: {
    score: {
      type: Number,
      required: true,
      default: 0,
      validator (value) {
        return Number.isInteger(value) && value >= 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

div {
  align-items: center;
  display: flex;
}

img {
  height: 6.3rem;
  width: auto;
}

span {
  @include brand-font(normal, false);

  font-size: 2rem;
  font-weight: normal;
  padding-bottom: 1.15rem;
}

@media screen and (min-width: 1024px) {
  img {
    height: 9.5rem;
  }

  span {
    font-size: 3rem;
    padding-bottom: 1.7rem;
  }
}
</style>
