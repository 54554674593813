<template>
  <div class="music-player" @click="toggleMusic">
    <p>Music - {{ isMusicOn ? 'On' : 'Off' }}</p>
    <img :src="icon" alt="">
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Howl } from 'howler';
import mainMusicStream from '../assets/sound/ES_Once in Kyoto - Ava Low.mp3';
import gameMusicStream from '../assets/sound/ES_Phase - AGST clip.mp3';
import musicOnIcon from '../assets/images/music-on.png';
import musicOffIcon from '../assets/images/music-off.png';

const MAX_VOLUME = 0.25;

export default {
  name: 'MusicPlayer',

  props: {
    track: {
      type: String,
      required: true,
      validator (value) {
        return ['main', 'game'].includes(value);
      }
    }
  },

  data: () => ({
    mainMusicSound: new Howl({
      src: [mainMusicStream],
      loop: true,
      volume: MAX_VOLUME
    }),

    gameMusicSound: new Howl({
      src: [gameMusicStream],
      loop: false,
      volume: MAX_VOLUME
    })
  }),

  computed: {
    ...mapState([
      'isMusicOn'
    ]),

    icon () {
      if (this.isMusicOn) {
        return musicOnIcon;
      }

      return musicOffIcon;
    },

    trackSound () {
      switch (this.track) {
        case 'game':
          return this.gameMusicSound;
      }

      return this.mainMusicSound;
    }
  },

  created () {
    this.$store.commit('REFRESH_MUSIC_STATE');
  },

  mounted () {
    // this.mainMusicSound.volume(this.isMusicOn ? MAX_VOLUME : 0);
    // this.gameMusicSound.volume(this.isMusicOn ? MAX_VOLUME : 0);

    if (this.isMusicOn) {
      this.trackSound.play();
    }
  },

  methods: {
    toggleMusic () {
      if (this.trackSound.playing()) {
        this.$store.commit('SET_MUSIC_STATE', false);
        this.fadeOut(this.trackSound);
      } else if (!this.trackSound.playing()) {
        this.$store.commit('SET_MUSIC_STATE', true);
        this.fadeIn(this.trackSound);
      }
    },

    fadeIn (sound) {
      if (!sound.playing()) {
        sound.fade(0, MAX_VOLUME, 1000);
        sound.play();
      }
    },

    fadeOut (sound) {
      if (sound.playing()) {
        sound.fade(MAX_VOLUME, 0, 1000);
        sound.once('fade', sound.stop);
      }
    },

    changeTrack () {
      if (!this.isMusicOn) {
        return;
      }

      [this.mainMusicSound, this.gameMusicSound].forEach((sound) => this.fadeOut(sound));
      this.fadeIn(this.trackSound);
    }
  },

  watch: {
    track (newTrack) {
      this.changeTrack(newTrack);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.music-player {
  display: flex;
  align-items: center;

  &:hover,
  > *:hover {
    cursor: url('../assets/images/cursor-highlight.png') 31 31, pointer;
  }

  p {
    @include brand-font(semibold);
    display: none;
    font-size: 1.2rem;

    @media screen and (min-width: 800px) {
      display: block;
    }
  }

  img {
    display: inline-block;
    margin-left: $spacing;
    padding-bottom: 3px;
  }
}
</style>
