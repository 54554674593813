<template>
  <div class="page">
    <h1 class="page__title title-text"># Your Score</h1>

    <div class="page__pairs">
      <span>X{{ $store.state.score.pairsMatched }}</span>
      Pairs
    </div>

    <p class="page__time-taken">
      In {{ Math.floor($store.state.score.timeTaken / 1000) }} seconds
    </p>

    <button-group class="page__actions" salign="center" align="center">
      <custom-button :to="{ name: 'leaderboard' }">
        Leaderboard
      </custom-button>
    </button-group>
  </div>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup.vue';
import CustomButton from '../components/CustomButton.vue';

export default {
  components: {
    ButtonGroup,
    CustomButton
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  align-items: center;
  align-content: center;
  display: grid;
  grid-template-areas:
    "title"
    "pairs"
    "timeTaken"
    "actions";
  justify-items: center;
  position: relative;
  row-gap: $spacing;

  &__title {
    background: url('../assets/images/score-icon.png') left center no-repeat;
    background-size: contain;
    grid-area: title;
    font-size: 2rem;
    margin: -25px 0;
    padding: 25px 3.2rem;
    text-align: center;
  }

  &__pairs {
    @include brand-font(light, false);
    align-items: center;
    background: url('../assets/images/hexagon.png') center no-repeat;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    grid-area: pairs;
    height: 15.6rem;
    justify-content: center;
    width: 17.7rem;

    > span {
      display: block;
      font-size: 2rem;
    }
  }

  &__time-taken {
    @include brand-font(light, false);

    color: $white;
    grid-area: timeTaken;
    text-align: center;
  }

  &__actions {
    grid-area: actions;
  }
}
</style>
