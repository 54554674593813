<template>
  <router-link v-if="$attrs.to" :class="buttonClasses" :to="$attrs.to" @mouseover.native="onHover" @click.native="onClick">
    <span>
      <slot></slot>
    </span>
  </router-link>
  <a v-else-if="$attrs.href" :class="buttonClasses" :href="$attrs.href" @mouseover.native="onHover" @click.native="onClick">
    <span>
      <slot></slot>
    </span>
  </a>
  <button v-else :type="submit ? 'submit' : 'button'" :disabled="disabled" :class="buttonClasses" @mouseover="onHover" @click="onClick">
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
import { Howl, Howler } from 'howler';

import buttonHoverStream from '../assets/sound/ES_Electronic Whoosh 2 - SFX Producer.mp3';
import buttonPressStream from '../assets/sound/ES_Multimedia Notify 23 - SFX Producer.mp3';

Howler.volume(0.25);

export default {
  name: 'CustomButton',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    submit: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: '',
      validator (value) {
        return ['', 'cta'].includes(value);
      }
    }
  },

  data: () => ({
    buttonHoverSound: new Howl({
      src: [buttonHoverStream]
    }),

    buttonPressSound: new Howl({
      src: [buttonPressStream]
    })
  }),

  computed: {
    buttonClasses () {
      const classes = ['button'];

      if (this.type.length > 0) {
        classes.push(`button--${this.type}`);
      }

      if (this.loading) {
        classes.push('button--loading');
      }

      return classes;
    }
  },

  methods: {
    onHover () {
      if (!this.disabled) {
        this.buttonHoverSound.play();
        this.$emit('mouseover');
      }
    },

    onClick () {
      if (!this.disabled) {
        this.buttonPressSound.play();
        this.$emit('click');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.button {
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px $grey-m solid;
  border-radius: 3px;
  color: $polished-d;
  font-size: 1.2rem;
  padding: 1.2em 3em;
  text-align: center;
  text-transform: uppercase;
  transition: transform 200ms ease-in-out;

  > span {
    @include brand-font(normal);
    display: inline-block;
  }

  &--cta {
    background-color: $polished-xxd;

    > span {
      color: $grey-l;
    }
  }

  &:hover {
    transform: scale3d(1.03, 1.03, 1);

    &,
    > *:hover {
      cursor: url('../assets/images/cursor.png') 23 23, auto;
    }
  }

  &[disabled] {
    // background-color: $grey-d;
    cursor: inherit;
    opacity: .7;

    > span {
      color: $grey-l;
    }

    &:hover {
      transform: none;

      &,
      > *:hover {
        cursor: inherit;
      }
    }
  }

  &--loading {
    background-image: url('../assets/images/tail-spin.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include bp-landscape() {
    font-size: 1.8rem;
  }
}
</style>
