<template>
  <div class="page">
    <div class="page__about">
      <h1 class="page__title title-text"># Leaderboard</h1>
    </div>

    <div class="page__leaderboard">
      <img v-if="highScores.length === 0" src="../assets/images/tail-spin.svg" alt="loading icon">
      <table v-else>
        <thead>
          <tr>
            <th class="game-rank" v-text="rank" />
            <th>Name</th>
            <th>Score</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(score, index) in highScores" :key="score.id" :class="getScoreClasses(score)">
            <th class="player-rank" v-text="(index + 1).toString().padStart(2, '0')" />
            <td class="player-name" v-text="score.playerName" />
            <td class="player-pairs" v-text="score.pairs" />
            <td class="player-time" v-text="getScoreTime(score)" />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="page__cta" v-if="$store.getters.isActivePlayer">
      <form v-if="!$store.state.interestRegistered" @submit.prevent="sendMeInfo">
        <h3>Interested in your own branded game?</h3>

        <button-group salign="center" align="center">
          <custom-button :loading="saving" type="cta" submit>Yes!</custom-button>
        </button-group>
      </form>

      <p v-else>Thanks, we will be in touch soon!</p>
    </div>

    <button-group class="page__navigation" align="right">
      <custom-button
        v-if="$store.getters.isActivePlayer"
        :to="{ name: 'game' }">
        Play again
      </custom-button>
      <custom-button
        v-else
        :to="{ name: 'home' }">
        Back to menu
      </custom-button>
    </button-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomButton from '../components/CustomButton.vue';
import ButtonGroup from '../components/ButtonGroup.vue';

export default {
  components: {
    ButtonGroup,
    CustomButton
  },

  data: () => ({
    saving: false
  }),

  computed: {
    ...mapState(['highScores']),

    contactMePreference: {
      get () {
        return this.$store.state.preferences.agreeToBeContacted;
      },

      set (value) {
        this.$store.commit('SET_AGREE_TO_BE_CONTACTED', !!value);
      }
    },

    rank () {
      const rank = this.$store.state.score.rank;
      return rank ? rank.toString().padStart(2, '0') : '#';
    }
  },

  created () {
    this.$store.dispatch('fetchHighScores');
  },

  methods: {
    getScoreClasses (score) {
      return {
        score: true,
        'score--selected': score.playerId === this.$store.state.player.id
      };
    },

    getScoreTime (score) {
      // if (score.timeTaken === 30000) {
      //   return '-';
      // }

      const str = (score.timeTaken / 1000).toString();
      const parts = str.split('.');
      if (!parts[1]) {
        return parts[0] + '.00';
      }
      if (parts[1].length === 1) {
        return parts[0] + '.' + parts[1] + '0';
      }

      return parts[0] + '.' + parts[1].substring(0, 2);
    },

    async sendMeInfo () {
      this.saving = true;
      try {
        await this.$store.dispatch('registerInterest');
      } catch (exception) {
        console.error(exception);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  display: grid;
  grid-template-areas:
    "about"
    "leaderboard"
    "cta"
    "navigation";
  justify-items: center;
  row-gap: $spacing;

  @media screen and (min-width: 1024px) {
    column-gap: $spacing;
    grid-template-areas:
      "about leaderboard"
      "cta leaderboard"
      "cta navigation";
    grid-template-columns: 4fr 6fr;
    grid-template-rows: 60% 1fr auto;
    justify-items: stretch;
  }

  &__about {
    align-self: center;
    grid-area: about;
  }

  &__title {
    font-size: 2.5rem;
    text-align: center;
  }

  &__leaderboard {
    grid-area: leaderboard;
    align-self: center;
    justify-self: center;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40vh;

    @media screen and (min-width: 1024px) {
      max-height: 60vh;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    th,
    td {
      padding: 1rem;
      text-align: left;
    }

    thead {
      th {
        @include brand-font(normal);
        padding-right: 0.75ex;

        @media screen and (min-width: 500px) {
          font-size: 2.2rem;
        }
      }

      .game-rank {
        background: url('../assets/images/pentagon-empty.png') center no-repeat;
        background-size: contain;
        font-size: 1.6rem;
        height: 55px;
        text-align: center;
        width: 58px;
      }
    }

    tbody {
      th,
      td {
        font-size: 1.2rem;
      }

      th {
        padding-right: 0.75ex;
      }

      td {
        font-family: $typeface-regular;
        color: $grey-l;
      }

      .player-rank {
        @include brand-font(normal);
        text-align: center;
        width: 10%;
      }

      .player-name {
        width: 40%;
      }

      .player-pairs {
        width: 20%;
      }

      .player-time {
        width: 30%;
      }
    }
  }

  &__cta {
    align-items: center;
    align-self: flex-end;
    border: $grey-xd 1px solid;
    display: flex;
    grid-area: cta;
    justify-content: center;
    padding: 4rem;

    h3,
    p {
      color: $grey-l;
      font-family: $typeface-regular;
      font-size: 1.2rem;
      text-align: center;
    }

    h3 {
      margin-bottom: 4rem;
    }

    .field {
      padding: 1rem;
    }

    label {
      @include brand-font(medium);
      color: $grey-l;
      font-size: 1.2rem;
    }
  }

  &__navigation {
    grid-area: navigation;
  }
}
</style>
