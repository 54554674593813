var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_vm._m(0),_c('validation-observer',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"page__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field field--text"},[_c('label',{staticClass:"field__label",attrs:{"for":"name"}},[_vm._v("Name")]),_c('div',{staticClass:"field__value"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name),expression:"name",modifiers:{"trim":true}}],ref:"nameField",attrs:{"id":"name","type":"text","autocomplete":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"field-errors"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field field--text"},[_c('label',{staticClass:"field__label",attrs:{"for":"company"}},[_vm._v("Company")]),_c('div',{staticClass:"field__value"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.company),expression:"company",modifiers:{"trim":true}}],attrs:{"id":"company","type":"text","autocomplete":"organization"},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"field-errors"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field field--text"},[_c('label',{staticClass:"field__label",attrs:{"for":"email"}},[_vm._v("Email")]),_c('div',{staticClass:"field__value"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],attrs:{"id":"email","type":"email","autocomplete":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"field-errors"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field field--text"},[_c('label',{staticClass:"field__label",attrs:{"for":"telephone"}},[_vm._v("Telephone")]),_c('div',{staticClass:"field__value"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.telephone),expression:"telephone",modifiers:{"trim":true}}],attrs:{"id":"telephone","type":"tel","autocomplete":"tel"},domProps:{"value":(_vm.telephone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.telephone=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"field-errors"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field field--checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreeToTerms),expression:"agreeToTerms"}],attrs:{"id":"acceptTerms","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agreeToTerms)?_vm._i(_vm.agreeToTerms,null)>-1:(_vm.agreeToTerms)},on:{"change":function($event){var $$a=_vm.agreeToTerms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agreeToTerms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agreeToTerms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agreeToTerms=$$c}}}}),_c('label',{staticClass:"field__label",attrs:{"for":"acceptTerms"}},[_c('span',[_vm._v("I agree to the "),_c('router-link',{attrs:{"to":{ name: 'terms' }}},[_vm._v("Terms & Conditions")])],1)])]),_c('div',{staticClass:"field-errors"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('button-group',{staticClass:"page__actions",attrs:{"align":"right"}},[_c('custom-button',{attrs:{"loading":_vm.saving,"disabled":invalid,"submit":""}},[_vm._v("Submit")])],1)],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__about"},[_c('h1',{staticClass:"page__title title-text"},[_vm._v("Tell us a little about you.")])])}]

export { render, staticRenderFns }