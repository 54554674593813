<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',

  props: {
    align: {
      type: String,
      default: 'right',
      validator (value) {
        return ['left', 'center', 'right'].includes(value);
      }
    },

    salign: {
      type: String,
      default: 'center',
      validator (value) {
        return ['left', 'center', 'right'].includes(value);
      }
    }
  },

  computed: {
    classes () {
      return {
        'button-group': true,

        'button-group--left': this.align === 'left',
        'button-group--center': this.align === 'center',
        'button-group--right': this.align === 'right',

        'button-group--small-left': this.salign === 'left',
        'button-group--small-center': this.salign === 'center',
        'button-group--small-right': this.salign === 'right'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &--small {
    &-left {
      align-items: flex-start;
    }

    &-center {
      align-items: center;
    }

    &-right {
      align-items: flex-end;
    }
  }

  > * + * {
    margin-top: 1em;
  }

  @include bp-landscape() {
    align-items: flex-end;
    flex-direction: row;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    > * + * {
      margin-left: 1em;
      margin-top: 0;
    }
  }
}
</style>
