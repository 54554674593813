import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';

Vue.use(Vuex);

function getMusicState () {
  return !sessionStorage.getItem('music') || sessionStorage.getItem('music') === 'on';
}

export default new Vuex.Store({
  state: {
    player: {
      id: sessionStorage.getItem('player-id'),
      token: sessionStorage.getItem('token'),
      name: '',
      company: '',
      email: '',
      telephone: '',
      agreeToTerms: false
    },

    interestRegistered: false,

    score: {
      pairsMatched: 0,
      timeTaken: 0,
      rank: 0
    },

    highScores: [],

    isMusicOn: getMusicState()
  },

  getters: {
    isActivePlayer (state) {
      return state.player.id && state.player.token;
    }
  },

  mutations: {
    SET_NAME (state, value) {
      state.player.name = value;
    },

    SET_COMPANY (state, value) {
      state.player.company = value;
    },

    SET_EMAIL (state, value) {
      state.player.email = value;
    },

    SET_TELEPHONE (state, value) {
      state.player.telephone = value;
    },

    SET_AGREE_TO_TERMS (state, value) {
      state.player.agreeToTerms = value;
    },

    SET_INTEREST_REGISTRERED (state, value) {
      state.interestRegistered = value;
    },

    SET_PAIRS_MATCHED (state, value) {
      state.score.pairsMatched = value;
    },

    SET_TIME_TAKEN (state, value) {
      state.score.timeTaken = value;
    },

    SET_RANK (state, value) {
      state.score.rank = value;
    },

    SET_HIGH_SCORES (state, scores) {
      state.highScores = scores;
    },

    REFRESH_MUSIC_STATE (state) {
      state.isMusicOn = getMusicState();
    },

    SET_MUSIC_STATE (state, value) {
      sessionStorage.setItem('music', value ? 'on' : 'off');
      state.isMusicOn = getMusicState();
    }
  },

  actions: {
    async createPlayer ({ state }) {
      const { name, company, email, telephone } = state.player;
      const data = { name, company, email, telephone };
      const response = await axios.post('api/players', data);

      state.player.id = response.data.id;
      state.player.token = response.data.token;

      sessionStorage.setItem('player-id', response.data.id);
      sessionStorage.setItem('token', response.data.token);
    },

    async submitScore ({ state, getters, commit }) {
      if (!getters.isActivePlayer) {
        return Promise.reject(new Error('no player details'));
      }

      const response = await axios.post(
        `api/players/${state.player.id}/scores`,
        state.score,
        {
          headers: {
            Authorization: `Bearer ${state.player.token}`
          }
        }
      );

      commit('SET_RANK', response.data.position);
    },

    async fetchHighScores ({ commit }) {
      const highScores = await axios.get('api/scores');
      commit('SET_HIGH_SCORES', highScores.data);
    },

    async registerInterest ({ state, getters, commit }) {
      if (!getters.isActivePlayer) {
        return Promise.reject(new Error('no player details'));
      }

      await axios.patch(
        `api/players/${state.player.id}/preferences`,
        {
          agreeToBeContacted: true
        },
        {
          headers: {
            Authorization: `Bearer ${state.player.token}`
          }
        }
      );

      commit('SET_INTEREST_REGISTRERED', true);
    }
  }
});
