<template>
  <div class="page">
    <h1 class="page__title title-text"># Match the pairs</h1>
    <div class="page__hud">
      <score-counter :score="pairsMatched" />
      <game-timer ref="gameTimer" :from="timeAllowedSeconds" @time-up="timeIsUp" />
    </div>

    <div class="page__playing-area">
      <div class="slot" v-for="(card, index) in cards" :key="index" @click="reveal(card)">
        <playing-card
          :front-image="card.img"
          back-image="back-gold-shape.png"
          :show="card.show"
          :is-matched="card.isMatched" />
      </div>
    </div>

    <modal-box v-if="showCountdownModal">
      <countdown-timer ref="countdownTimer" :from="3" @time-up="startGame" />
    </modal-box>

    <modal-box v-if="showTimeUpModal" class="modal-content">
      <img src="../assets/images/hash-icon.png" alt="">
      <h2>Time is up!</h2>
    </modal-box>

    <modal-box v-if="showCompletedModal" class="modal-content">
      <img src="../assets/images/hash-icon.png" alt="">
      <h2>Well done!</h2>
      <!-- <p>You matched all the pairs with {{ Math.ceil($store.state.score.timeTaken / 1000) }} seconds to spare!</p> -->
    </modal-box>

  </div>
</template>

<script>
import { Howl, Howler } from 'howler';
import { shuffle, cloneDeep } from 'lodash';

import PlayingCard from '../components/PlayingCard.vue';
import CountdownTimer from '../components/CountdownTimer.vue';
import GameTimer from '../components/GameTimer.vue';
import ScoreCounter from '../components/ScoreCounter.vue';
import ModalBox from '../components/ModalBox.vue';

import cardMatchStream from '../assets/sound/ES_MM Chime 77 - SFX Producer.mp3';
import gameCompleteStream from '../assets/sound/ES_PE Whoosh To Hit 8 - SFX Producer.mp3';

Howler.volume(0.25);

const GAME_STAGE = {
  INTRO: 'intro',
  COUNTDOWN: 'countdown',
  GAME: 'game',
  COMPLETE: 'complete',
  TIMEUP: 'time-up'
};

export default {
  components: {
    PlayingCard,
    CountdownTimer,
    GameTimer,
    ScoreCounter,
    ModalBox
  },

  data: () => ({
    stage: GAME_STAGE.INTRO,

    cardImages: [
      'front-branded-games.png',
      'front-data-capture.png',
      'front-interactive-activities.png',
      'front-interactive-content.png',
      'front-surveys-market-research.png',
      'front-virtual-spaces.png'
    ],

    cards: [],
    flippedCards: [],

    cardMatchSound: new Howl({
      src: [cardMatchStream]
    }),

    gameCompleteSound: new Howl({
      src: [gameCompleteStream]
    })
  }),

  computed: {
    showCountdownModal () {
      return this.stage === GAME_STAGE.COUNTDOWN;
    },

    showTimeUpModal () {
      return this.stage === GAME_STAGE.TIMEUP;
    },

    showCompletedModal () {
      return this.stage === GAME_STAGE.COMPLETE;
    },

    pairsMatched () {
      return this.cards.filter(card => card.isMatched).length / 2;
    },

    timeAllowedSeconds () {
      return 30;
    }
  },

  created () {
    const baseCards = this.cardImages.map((image) => ({
      img: image,
      show: false,
      isMatched: false
    }));

    this.cards = shuffle(
      [].concat(
        cloneDeep(baseCards),
        cloneDeep(baseCards)
      )
    );
  },

  mounted () {
    this.setStage(GAME_STAGE.COUNTDOWN);
  },

  methods: {
    async setStage (stage) {
      this.stage = stage;

      switch (stage) {
        case GAME_STAGE.INTRO:
          break;

        case GAME_STAGE.COUNTDOWN:
          await this.$nextTick();
          this.$refs.countdownTimer.start();
          break;

        case GAME_STAGE.GAME:
          this.$refs.gameTimer.start();
          break;

        case GAME_STAGE.COMPLETE: {
          const timeTaken = this.$refs.gameTimer.stop();

          this.$store.commit('SET_PAIRS_MATCHED', this.cardImages.length);
          this.$store.commit('SET_TIME_TAKEN', timeTaken);
          this.$store.dispatch('submitScore');

          setTimeout(() => {
            this.$router.push({ name: 'score' });
          }, 3000);
          break;
        }

        case GAME_STAGE.TIMEUP:
          this.$store.commit('SET_PAIRS_MATCHED', this.pairsMatched);
          this.$store.commit('SET_TIME_TAKEN', this.timeAllowedSeconds * 1000);
          this.$store.dispatch('submitScore');

          setTimeout(() => {
            this.$router.push({ name: 'score' });
          }, 3000);
          break;
      }
    },

    startGame () {
      this.setStage(GAME_STAGE.GAME);
    },

    reveal (card) {
      if (!this.allowedToRevealCard(card)) {
        return;
      }

      card.show = true;

      if (this.flippedCards.length < 2) {
        this.flippedCards.push(card);
      }

      if (this.flippedCards.length === 2) {
        this.match(card);
      }
    },

    allowedToRevealCard (card) {
      return this.stage === GAME_STAGE.GAME &&
        !card.isMatched &&
        !card.show &&
        this.flippedCards.length < 2;
    },

    match (card) {
      if (this.flippedCards[0].img === this.flippedCards[1].img) {
        this.cardMatchSound.play();
        setTimeout(() => {
          this.flippedCards.forEach(this.setMatched);
          this.flippedCards = [];

          const finished = this.cards.every(card => card.isMatched);
          if (finished) {
            this.gameCompleteSound.play();
            this.setStage(GAME_STAGE.COMPLETE);
          }
        }, 400);
      } else {
        setTimeout(() => {
          this.flippedCards.forEach(this.hide);
          this.flippedCards = [];
        }, 800);
      }
    },

    setMatched (card) {
      card.isMatched = true;
    },

    hide (card) {
      card.show = false;
    },

    timeIsUp () {
      this.setStage(GAME_STAGE.TIMEUP);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  align-items: center;
  display: grid;
  grid-template-areas:
    "title"
    "hud"
    "cards";
  justify-content: center;
  row-gap: $spacing;

  @media screen and (min-width: 810px) {
    column-gap: $spacing;
    grid-template-areas:
      "title hud"
      "cards cards";
    grid-template-columns: 6fr 2fr;
    justify-content: stretch;
  }

  @media screen and (min-width: 1250px) {
    grid-template-areas:
      "cards title"
      "cards hud";
    grid-template-columns: 6fr 2fr;
    grid-template-rows: 1fr auto;
  }

  &__title {
    align-self: center;
    font-size: 2.7rem;
    grid-area: title;
    text-align: center;

    @media screen and (min-width: 810px) {
      padding-bottom: 1rem;
      text-align: inherit;
    }

    @media screen and (min-width: 1250px) {
      text-align: center;
    }
  }

  &__hud {
    align-items: center;
    grid-area: hud;
    display: flex;
    justify-content: center;
    height: 5rem;
    position: sticky;

    @media screen and (min-width: 810px) {
      justify-content: flex-end;
      height: auto;
      position: static;
    }

    > * + * {
      margin-left: 4rem;
    }
  }

  &__playing-area {
    align-items: center;
    display: flex;
    grid-area: cards;
    justify-content: center;
    justify-self: center;
    flex-wrap: wrap;
    margin: 0 -2.5rem;
    max-width: 600px;

    @media screen and (min-width: 600px) {
      margin: 0;
      padding: 3px;
    }

    @media screen and (min-width: 1010px) {
      max-width: 100%;
      width: 750px;
    }

    @media screen and (min-width: 1250px) {
      width: 600px;
    }

    @media screen and (min-width: 1250px) {
      width: 850px;
    }

    .slot {
      display: flex;
      justify-content: center;
      padding: 2px;

      @media screen and (min-width: 600px) {
        padding: 3px;
      }

      @media screen and (min-width: 810px) {
        padding: 4px;
      }
    }
  }
}
</style>
