<template>
  <div>
    <span v-text="remainingSeconds" />
  </div>
</template>

<script>
import countdownTimerMixin from '../mixins/countdownTimerMixin';

export default {
  name: 'GameTimer',
  mixins: [countdownTimerMixin]
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

div {
  position: relative;
  background: url('../assets/images/pentagon.png');
  background-size: cover;
  height: 12rem;
  width: 12.1rem;

  span {
    @include brand-font(normal);

    display: block;
    font-size: 2rem;
    font-weight: normal;
    left: .25rem;
    right: 3.75rem;
    position: absolute;
    text-align: center;
    top: 4.2rem;
  }

  @media screen and (min-width: 1024px) {
    height: 18rem;
    width: 18.1rem;

    span {
      font-size: 3rem;
      left: .4rem;
      right: 5.6rem;
      top: 6.5rem;
    }
  }
}
</style>
