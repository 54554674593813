<template>
  <div class="app" :class="containerClasses">
    <header class="app__header">
      <a href="https://www.polishedrockltd.co.uk/memory-game">
        <img src="./assets/images/logo.png" alt="Polished Rock logo" class="app__logo">
      </a>

      <music-player class="app__music-player" :track="track" />
    </header>

    <router-view class="app__content" />
  </div>
</template>

<script>
import MusicPlayer from './components/MusicPlayer.vue';

export default {
  components: {
    MusicPlayer
  },

  computed: {
    containerClasses () {
      return [`app--${this.$route.name}`];
    },

    track () {
      switch (this.$route.name) {
        case 'game':
          return 'game';
      }

      return 'main';
    }
  }
};
</script>

<style lang="scss">
@import 'assets/scss/base';

$cursor: url('assets/images/cursor.png') 23 23, auto;
$cursor-highlight: url('assets/images/cursor-highlight.png') 31 31, pointer;

html {
  font-size: 10px;
  height: 100%;
  min-height: 100%;

  @media screen and (min-width: 840px) {
    font-size: 12.5px;
  }
}

body {
  background: url('assets/images/background.jpg') center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  cursor: $cursor;
  height: 100%;
  min-height: 100%;
}

.app {
  $self: &;

  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: 112px 1fr;
  min-height: 100%;
  padding: $spacing;
  position: relative;
  row-gap: $spacing;
  z-index: 10;

  &--home {
    background-attachment: fixed;
    background-image: url('assets/images/cards-falling.png');
    background-position: 75% top;
    background-repeat: no-repeat;
    background-size: auto 100%;

    @include bp-landscape() {
      background-image: url('assets/images/cards-falling.png'),
        url('assets/images/light.png');
      background-position: 75% top, right top;
    }
  }

  &--form {
    @media screen and (min-width: 800px) {
      background: url('assets/images/card-stack.png') left bottom no-repeat fixed;
    }
  }

  &--instructions {
    background: url('assets/images/cards-falling.png');
    background-position: 33% top;
    background-repeat: no-repeat;
    background-size: auto;

    @include bp-landscape() {
      background-image: url('assets/images/cards-falling.png'),
        url('assets/images/light.png');
      background-position: 33% top, right top;
      background-size: auto, cover;
    }
  }

  &__header {
    display: flex;
    grid-area: header;
    justify-content: space-between;

    > a {
      display: block;
    }
  }

  &--game {
    @media screen and (max-width: 800px) {
      grid-template-areas:
        "content";
      grid-template-rows: 1fr;

      .app__header {
        display: none;
      }
    }
  }

  &__logo {
    width: 13rem;
  }

  &__content {
    grid-area: content;
  }
}

input,
label {
  cursor: $cursor;
}

input[type="checkbox"] {
  $self: &;
  display: none;

  & + label::before {
    background-color: $form-field;
    content: '';
    display: inline-block;
    height: 6rem;
    margin-right: 1em;
    vertical-align: middle;
    width: 6rem;
  }

  &:checked + label::before {
    background-image: url('./assets/images/checkbox-icon.png');
    background-position: center;
    background-repeat: no-repeat;
  }
}

a:hover {
  cursor: $cursor-highlight;
}

.title-text {
  @include brand-font(light);

  font-size: 3rem;
}
</style>
