<template>
  <div class="page">
    <h1 class="page__title title-text">Memory Cards</h1>

    <h2 class="page__cta">Play to Win a PS5!</h2>

    <button-group class="page__actions" align="center">
      <custom-button :to="{ name: 'form' }">Start game</custom-button>
      <!-- <custom-button :to="{ name: 'leaderboard' }">Leaderboard</custom-button> -->
    </button-group>

    <p class="page__disclaimer"><small>Terms and conditions apply.</small></p>
  </div>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup.vue';
import CustomButton from '../components/CustomButton.vue';

export default {
  components: {
    ButtonGroup,
    CustomButton
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  display: grid;
  grid-template-areas:
    "title"
    "cta"
    "actions"
    "disclaimer";
  justify-items: center;
  padding-top: 10vh;
  row-gap: $spacing;

  &__title {
    grid-area: title;
    font-size: 4rem;
    text-align: center;

    @media screen and (min-width: 810px) {
      font-size: 6rem
    }
  }

  &__cta {
    @include brand-font(medium, false, false);
    grid-area: cta;
    color: $polished-xl;
    font-size: 3rem;
    text-align: center;

    @media screen and (min-width: 810px) {
      font-size: 4rem;
    }
  }

  &__actions {
    grid-area: actions;
  }

  &__disclaimer {
    grid-area: disclaimer;
    color: $grey-l;
    font-family: $typeface-display;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
  }
}
</style>
