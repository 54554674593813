export default {
  props: {
    from: {
      type: Number,
      required: true,
      default: 5,
      validator (value) {
        return Number.isInteger(value) && value > 0;
      }
    }
  },

  data: () => ({
    timeTakenMilliseconds: 0,
    startTime: 0,
    interval: undefined
  }),

  computed: {
    fromMilliseconds () {
      return this.from * 1000;
    },

    remainingMilliseconds () {
      return this.fromMilliseconds - this.timeTakenMilliseconds;
    },

    remainingSeconds () {
      return Math.ceil(this.remainingMilliseconds / 1000);
    },

    timeTakenSeconds () {
      return Math.floor(this.timeTakenMilliseconds / 1000);
    }
  },

  methods: {
    start () {
      this.startTime = Date.now();
      this.setRemainingTime();

      this.interval = setInterval(() => {
        this.setRemainingTime();

        if (this.timeTakenMilliseconds > this.fromMilliseconds) {
          this.timeTakenMilliseconds = this.fromMilliseconds;

          if (this.waitExtraSecond) {
            setTimeout(() => {
              this.$emit('time-up');
            }, 1000);
          } else {
            this.$emit('time-up');
          }

          this.stop();
        }
      }, 10);
    },

    setRemainingTime () {
      this.timeTakenMilliseconds = Date.now() - this.startTime;
    },

    stop () {
      clearInterval(this.interval);
      return this.timeTakenMilliseconds;
    }
  }
};
