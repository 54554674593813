<template>
  <div class="page">
    <div class="page__about">
      <h1 class="page__title title-text">Tell us a little about you.</h1>
    </div>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(onSubmit)" class="page__form">
        <validation-provider rules="required" v-slot="{ errors }" tag="div">
          <div class="field field--text">
            <label for="name" class="field__label">Name</label>
            <div class="field__value">
              <input ref="nameField" id="name" v-model.trim="name" type="text" autocomplete="name">
            </div>
          </div>
          <div class="field-errors">
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <validation-provider rules="required" v-slot="{ errors }" tag="div">
          <div class="field field--text">
            <label for="company" class="field__label">Company</label>
            <div class="field__value">
              <input id="company" v-model.trim="company" type="text" autocomplete="organization">
            </div>
          </div>
          <div class="field-errors">
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <validation-provider rules="required|email" v-slot="{ errors }" tag="div">
        <div class="field field--text">
            <label for="email" class="field__label">Email</label>
            <div class="field__value">
              <input id="email" v-model.trim="email" type="email" autocomplete="email">
            </div>
          </div>
          <div class="field-errors">
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <validation-provider rules="required" v-slot="{ errors }" tag="div">
          <div class="field field--text">
            <label for="telephone" class="field__label">Telephone</label>
            <div class="field__value">
              <input id="telephone" v-model.trim="telephone" type="tel" autocomplete="tel">
            </div>
          </div>
          <div class="field-errors">
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" tag="div">
          <div class="field field--checkbox">
            <input id="acceptTerms" type="checkbox" v-model="agreeToTerms">
            <label for="acceptTerms" class="field__label">
              <span>I agree to the <router-link :to="{ name: 'terms' }">Terms &amp; Conditions</router-link></span>
            </label>
          </div>
          <div class="field-errors">
            <span>{{ errors[0] }}</span>
          </div>
        </validation-provider>

        <button-group class="page__actions" align="right">
          <custom-button :loading="saving" :disabled="invalid" submit>Submit</custom-button>
        </button-group>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, setInteractionMode, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

import ButtonGroup from '../components/ButtonGroup.vue';
import CustomButton from '../components/CustomButton.vue';

setInteractionMode('lazy');

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('email', email);

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ButtonGroup,
    CustomButton
  },

  data: () => ({
    saving: false
  }),

  computed: {
    name: {
      get () {
        return this.$store.state.player.name;
      },
      set (value) {
        this.$store.commit('SET_NAME', value);
      }
    },

    company: {
      get () {
        return this.$store.state.player.company;
      },
      set (value) {
        this.$store.commit('SET_COMPANY', value);
      }
    },

    email: {
      get () {
        return this.$store.state.player.email;
      },
      set (value) {
        this.$store.commit('SET_EMAIL', value);
      }
    },

    telephone: {
      get () {
        return this.$store.state.player.telephone;
      },
      set (value) {
        this.$store.commit('SET_TELEPHONE', value);
      }
    },

    agreeToTerms: {
      get () {
        return this.$store.state.player.agreeToTerms;
      },
      set (value) {
        this.$store.commit('SET_AGREE_TO_TERMS', value);
      }
    }
  },

  mounted () {
    this.$refs.nameField.focus();
  },

  methods: {
    async onSubmit () {
      this.saving = true;

      try {
        await this.$store.dispatch('createPlayer');
        this.$router.push({ name: 'instructions' });
      } catch (exception) {
        console.error(exception);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  display: grid;
  grid-template-areas:
    "about"
    "form";
  row-gap: $spacing;

  @media screen and (min-width: 800px) {
    column-gap: $spacing;
    grid-template-areas:
      "about form";
    grid-template-columns: 50% auto;
  }

  &__about {
    grid-area: about;
  }

  &__title {
    font-size: 2.7rem;
  }

  &__form {
    grid-area: form;

    > * + * {
      margin-top: 3rem;
    }
  }
}

.field {
  $self: &;

  font-size: 1.2rem;

  &--text {
    align-items: center;
    background-color: $form-field;
    display: flex;
  }

  &__label {
    @include brand-font(medium);
    text-transform: uppercase;

    #{$self}--text & {
      flex: 1;
      padding: 1.5em;

      &::after {
        content: '*';
      }
    }

    #{$self}--checkbox & {
      align-items: center;
      color: $grey-l;
      display: flex;
    }

    a {
      text-decoration: underline;
    }
  }

  &__value {
    flex: 3;

    input {
      color: $grey-l;
      font-family: $typeface-light;
      font-size: inherit;
      padding: 1.5em;
      width: 100%;
    }
  }
}

.field-errors {
  @include brand-font(semibold, false, false);
  color: $grey-l;
  font-size: 1.2rem;
  margin-top: 1em;
}
</style>
