import Vue from 'vue';
import VueRouter from 'vue-router';

import SplashscreenPage from '../views/SplashscreenPage.vue';
import FormPage from '../views/FormPage.vue';
import TermsAndConditionsPage from '../views/TermsAndConditionsPage.vue';
import InstructionsPage from '../views/InstructionsPage.vue';
import GamePage from '../views/GamePage.vue';
import ScorePage from '../views/ScorePage.vue';
import LeaderboardPage from '../views/LeaderboardPage.vue';

import store from '../store/index';

Vue.use(VueRouter);

const redirectNewPlayers = (to, from, next) => {
  if (!store.getters.isActivePlayer && process.env.NODE_ENV === 'production') {
    next({ name: 'home' });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: SplashscreenPage
  },
  {
    path: '/contact-details',
    name: 'form',
    component: FormPage
  },
  {
    path: '/terms-and-conditions',
    name: 'terms',
    component: TermsAndConditionsPage
  },
  {
    path: '/how-to-play',
    name: 'instructions',
    component: InstructionsPage,
    beforeEnter: redirectNewPlayers
  },
  {
    path: '/game',
    name: 'game',
    component: GamePage,
    beforeEnter: redirectNewPlayers
  },
  {
    path: '/score',
    name: 'score',
    component: ScorePage,
    beforeEnter: redirectNewPlayers
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: LeaderboardPage
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
