<template>
  <div class="page">
    <div class="page__content">
      <h1 class="page__title title-text"># How to play</h1>
      <div class="page__copy">
        <p>You have 30 seconds to match as many cards as possible.</p>
        <p># GOOD LUCK</p>
      </div>
    </div>

    <button-group class="page__actions" salign="center" align="right">
      <custom-button :to="{ name: 'game' }">Start</custom-button>
    </button-group>
  </div>
</template>

<script>
import ButtonGroup from '../components/ButtonGroup.vue';
import CustomButton from '../components/CustomButton.vue';

export default {
  components: {
    ButtonGroup,
    CustomButton
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/base';

.page {
  display: grid;
  grid-template-areas:
    "info"
    "actions";
  row-gap: $spacing;

  @media screen and (min-width: 800px) {
    padding: 15vh 15vw 0 15vw;
  }

  &__content {
    grid-area: info;
  }

  &__title {
    font-size: 2.7rem;
    margin-bottom: $spacing;
  }

  &__copy {
    color: $grey-d;
    font-family: $typeface-regular;
    font-size: 1.6rem;

    p {
      margin-bottom: 1em;;
    }
  }

  &__actions {
    grid-area: actions;
    align-self: end;
  }
}
</style>
